import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import useScript from '../service/useScript';
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import Link from "../components/addon/Link"

const OnlineServices = ({ data: { allNodeTemplatePages: { edges } } }) => {
    useScript("/assets/js/emergencyServices.js");

    const [openModal, setOpenModal] = useState(null);

    const [pageData] = edges;

    const metaTags = Array.isArray(pageData?.node?.metatags) && pageData?.node?.metatags.length > 0 ? pageData?.node?.metatags : [];
    const schema = pageData?.node?.relationships?.field_seo_schema ? pageData?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = pageData?.node?.metatags_on_page || {}
    const title = typeof (pageData?.node?.title) !== "undefined" && pageData?.node?.title.length > 0 ? pageData?.node?.title : "KneeImplantPricing"
    const pageDetails = typeof (pageData) !== "undefined" && Array.isArray(pageData?.node?.relationships?.components) && pageData?.node?.relationships?.components.length > 0 && pageData?.node?.relationships?.components

    const clickToBookApp = () => {
        navigate(`/book-an-appointment`, { state: { appointmentFor: 'PHY' } })
    }

    return (
        <Layout>
            <Meta
                files={
                    {
                        js: [],
                        css: ["/assets/css/common.css", "/assets/css/services.css", "/assets/css/online-services.css"]
                    }
                }
                tags={{metaTags,canonicalUrl}}
            />
            <Schema schema={schema} />
            <main className="innerpage">
                <section className="section_bg pt-first-section pb-section onlineServiceWrap">
                    <div className="container">
                        <div className="section-head">
                            <h2 className="section-heading">{title ? title : "Online Services"}</h2>
                        </div>
                        {pageDetails &&
                            <>
                                {pageDetails.map((section, i) => {
                                    const comps = section?.relationships?.components || [];
                                    return(
                                        <div className="serviceBoxWrap" key={i}>
                                            <h3>{section?.name}</h3>
                                            <div className="serviceBox">
                                                <div className="row">
                                                    {comps.map((c, ci) => {
                                                        const title = c?.title;
                                                        const link = c?.link;
                                                        const img = c?.relationships?.icon?.uri?.url;
                                                        const modalData = c?.field_text?.processed ? c?.field_text?.processed.replace(/classname=/g, 'class=') : '';
                                                        return(
                                                            <div className="col-md-12 col-lg-6 paymentCardWrap" key={ci}>
                                                                <div className="paymentCard">
                                                                    <div className="imgWithText">
                                                                        {img &&
                                                                            <div className="imageSec">
                                                                                <img src={img} alt={title}/>
                                                                            </div>
                                                                        }
                                                                        {title &&
                                                                            <div className="textSec">
                                                                                <p>{title}</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="btnWrap">
                                                                        {link &&
                                                                            <div className="btnSec">
                                                                                {
                                                                                    title.indexOf('Physiotherapy') > -1 ?
                                                                                    <button className="btn btn-primary" onClick={clickToBookApp}>{ c.link.title}</button>
                                                                                    :
                                                                                <Link classes="btn btn-primary" data={c}/>
                                                                                }
                                                                            </div>   
                                                                        }
                                                                        {/* {modalData &&
                                                                            <div className="btnSec">
                                                                                <a className="btn-link" data-target={`#s${i}c${ci}`} data-toggle="modal" href="javascript:;">View Instruction</a>
                                                                            </div>
                                                                        } */}
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                        )
                                                    })} 
                                                </div>  
                                            </div>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </section>
            </main>
            {pageDetails &&
                <>
                    {pageDetails.map((section, i) => {
                        const comps = section?.relationships?.components || [];
                        return(
                            <>
                                {comps.map((c, ci) => {
                                    const modalData = c?.field_text?.processed ? c?.field_text?.processed.replace(/classname=/g, 'class=') : '';
                                    return(
                                        <div className="modal common_modal fade pay_modal modal-center" id={`s${i}c${ci}`} tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true" dangerouslySetInnerHTML={{ __html: modalData }}></div> 
                                    )
                                })} 
                            </>
                        )
                    })}
                </>
            }
        </Layout>
    )
}

export const query = graphql`
query onlineServicesPage {
    allNodeTemplatePages(filter: {path: {alias: {regex: "/online-services$/"}}}) {
    edges {
    node {
    id
    title
    path {
    alias
    }
    relationships{
        field_seo_schema{
            field_seo_title
            field_seo_schema_text_area
        }
    }
    metatags: metatag_normalized {
    attributes {
    content
    name
    property
    }
    }
    metatags_on_page: field_meta_tags{
        canonical_url
      }
    relationships {
    components: field_component_type {
    __typename
    ...ParagraphSectionOS
    }
    }
    }
    }
    }
    }
    
    
    
    fragment ParagraphSectionOS on paragraph__section {
    id
    name: field_name
    relationships {
    components: field_components {
    __typename
    ...ParagraphOnlineServices
    }
    }
    }
    
    
    
    fragment ParagraphOnlineServices on paragraph__online_services {
    id
    title: field_title
    blurb: field_blurb
    icon: field_icon {
    alt
    }
    link: field_link {
    uri
    title
    }
    relationships {
    icon: field_icon {
    id
    uri {
        value
        url
    }
    }
    }
    field_text {
    processed
    }
    }
`
export default OnlineServices